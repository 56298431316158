import { createTheme } from '@mui/material/styles'
import colors from './colors'

const theme = createTheme({
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                html: {
                    scrollBehavior: 'smooth',
                    '&::-webkit-scrollbar': {
                        width: 12
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: '#ececec',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        borderRadius: 8,
                        backgroundColor: '#d1d1d1',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        background: '#c1c1c1',
                    },
                },
            },
        },
    },
    palette: {
        primary: {
            main: colors.primary,
        },
        secondary: {
            main: colors.secondary,
        },
        warning: {
            main: colors.warning
        },
        text: {
            primary: 'rgba(0, 0, 0, .8)'
        }
    },
    typography: {
        fontFamily:[
            'Asap',
            'sans-serif',
        ].join(','),
        body1: {
            fontSize: '1.2rem'
        }
    },
    shape:{
        borderRadius: 6
    },
})


export default theme