import * as React from 'react'
import ThemeProvider from './src/components/atoms/ThemeProvider/ThemeProvider'
import { Provider as ReduxProvider } from 'react-redux'
import store from './src/utils/store'
import "firebase/functions"
import "firebase/firestore"
import 'firebase/storage'

export const wrapRootElement = ({ element }) => {
    return (
        <ReduxProvider store={ store }>
            <ThemeProvider>
                { element }
            </ThemeProvider>
        </ReduxProvider>
    )
}