import React from "react"
import theme from '@styles/theme'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { Helmet } from 'react-helmet'

const ThemeProvider = props => (
    <>
        <Helmet>
            <meta name="viewport" content="initial-scale=1, width=device-width" />
            <link href="https://fonts.googleapis.com/css?family=Asap:400,500,700&display=swap" rel="stylesheet"/>
        </Helmet>
        <MuiThemeProvider theme={ theme }>
            <CssBaseline />
            { props.children }
        </MuiThemeProvider>
    </>
)


export default ThemeProvider