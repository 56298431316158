import { SUB_CHANGE_VALUE, SUB_CHANGE_CHILD_VALUE, SUB_CHANGE_ERROR, SUB_CLEAN } from '@constants/actionTypes'
import { CURRENT_EDITION } from '@constants/editions'

const INITIAL_STATE = {
    profile: {
        name: '',
        cpf: '',
        rg: '',
        dateOfBirth: '',
        phone: '',
        address: '',
        email: '',
        error: [],                // If some input is invalid, the input id will be saved here
        underAge: false           // When user change the birth input, will change this field
    },
    photo: {
        category: Object.values(CURRENT_EDITION.categories)[0].id,
        title: '',
        place: '',
        error: [],              // If some input is invalid, the input id will be saved here
        file: null,
    },
    auths: {
        underAge: null,
        peoples: []
    },
    stepper: {
        index: 0,
        warning: '',            // If have some warning like if user forgot the photo or some auth, will store the message
        error: '',              // If have some error when sending subscription
        loading: ''             // If loading some step, like validating the user identity, or when saving the subscription
    },
    isMobile: false,  
    device: null,               // Device infos
    dialogSuccess: false,
    subscriptionID: ''          // After subcribe, this will store the subscription id
}

const reducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case SUB_CHANGE_VALUE: 
            return {
                ...state,
                [action.property]: action.payload
            }
        case SUB_CHANGE_CHILD_VALUE:
            return {
                ...state,
                [action.parent]: {
                    ...state[action.parent],
                    [action.property]: action.payload
                }
            }
        case SUB_CHANGE_ERROR:
            return {
                ...state,
                [action.parent]: {
                    ...state[action.parent],
                    error: action.add ? [...state[action.parent].error, action.payload] : state[action.parent].error.filter(el => el !== action.payload)
                }
            }
        case SUB_CLEAN:
            return INITIAL_STATE
        default: 
            return state
    }
}

export default reducer