exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-exibicao-js": () => import("./../../../src/pages/exibicao.js" /* webpackChunkName: "component---src-pages-exibicao-js" */),
  "component---src-pages-fotos-premiadas-js": () => import("./../../../src/pages/fotos-premiadas.js" /* webpackChunkName: "component---src-pages-fotos-premiadas-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inscricao-js": () => import("./../../../src/pages/inscricao.js" /* webpackChunkName: "component---src-pages-inscricao-js" */),
  "component---src-pages-jurados-js": () => import("./../../../src/pages/jurados.js" /* webpackChunkName: "component---src-pages-jurados-js" */),
  "component---src-pages-premios-js": () => import("./../../../src/pages/premios.js" /* webpackChunkName: "component---src-pages-premios-js" */),
  "component---src-pages-sobre-js": () => import("./../../../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */)
}

