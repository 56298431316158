import dayjs from "dayjs"

const EDITIONS = {
    // "2022": {
    //     id: '2022',
    //     name: '2022',
    //     index: 5,
    //     categories: {
    //         associate: { id: 'associate', name: 'Cooperados', description: 'Meu povo, minha terra' },
    //         professional: { id: 'professional', name: 'Profissionais', description: 'Sustentabilidade profissional' },
    //         unassociated: { id: 'unassociated', name: 'Não cooperados', description: 'Sustentabilidade' },
    //     }
    // },
    "2021": {
        id: '2021',
        name: '2021',
        index: 4,
        categories: {
            associate: { id: 'associate', name: 'Cooperados', description: 'Meu povo, minha terra' },
            professional: { id: 'professional', name: 'Profissionais', description: 'Sustentabilidade profissional' },
            unassociated: { id: 'unassociated', name: 'Não cooperados', description: 'Sustentabilidade' },
        }
    },
    "2020": {
        id: '2020',
        name: '2020',
        index: 3,
        categories: {
            associate: { id: 'associate', name: 'Cooperados', description: 'Meu povo, minha terra' },
            professional: { id: 'professional', name: 'Profissionais', description: 'Sustentabilidade profissional' },
            unassociated: { id: 'unassociated', name: 'Não cooperados', description: 'Sustentabilidade' },
        }
    },
    "2019": {
        id: '2019',
        name: '2019',
        index: 2,
        categories: {
            associate: { id: 'associate', name: 'Cooperados', description: 'Meu povo, minha terra' },
            professional: { id: 'professional', name: 'Profissionais', description: 'Sustentabilidade profissional' },
            unassociated: { id: 'unassociated', name: 'Não cooperados', description: 'Sustentabilidade' },
        }
    },
    "2018": {
        id: '2018',
        name: '2018',
        index: 1,
        categories: {
            associate: { id: 'associate', name: 'Cooperados', description: 'Meu povo, minha terra' },
            professional: { id: 'professional', name: 'Profissionais', description: 'Sustentabilidade profissional' },
            unassociated: { id: 'unassociated', name: 'Não cooperados', description: 'Sustentabilidade' },
        }
    }
}

// export const CURRENT_EDITION = EDITIONS[dayjs().year().toString()]
export const CURRENT_EDITION = EDITIONS['2021']

export default EDITIONS